import React from 'react';

import '../common-style.css'

export const Statistics = () => {
    return (
        <div className='box'>
            <h1 className="text">Sekcja statystyki jest obecnie w budowie. Spróbuj ponownie później.</h1>
        </div>

    )
}